.nut-button {
  &.nut-button--primary {
    &:active {
      background: #0c4ee8;
      &:before {
        opacity: 0;
      }
    }
    &.nut-button--disabled {
      opacity: 1;
      background: #a1bdff;
    }
  }
  &.nut-button--default {
    color: #165dff;
    background: #e8f3ff;
    border-color: transparent;
    &:active {
      background: #8cbaff;
    }
    &.nut-button--disabled {
      color: #94bfff;
      background: #e8f3ff;
    }
  }
  &.nut-button--plain {
    background: transparent !important;
    border-color: currentColor !important;
    color: #165dff;
    &:active {
      background: #e8f3ff !important;
      &:before {
        opacity: 0;
      }
    }
    &.nut-button--disabled {
      color: #94bfff;
      background: transparent;
    }
  }
}
